import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../components/SignIn/SignIn.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import PinInput from "react-pin-input";
import * as Axios from "../../utils/API";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';

export default function EnterVerificationCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const [resentOtp,setResentOtp] = useState<number>(0)
  const [mobile_number,setMobileNumber] = useState('')
  const [apiCallInProgress,setApiCallInProgress] = useState(false)
  const [otpError,setOtpError] = useState("")

  useEffect(()=>{
    getSessionCreds()
  },[resentOtp])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('number')

    if(requiredNumber){
      setMobileNumber(requiredNumber)
    }
    else{
      navigate('/')
    }
  }

  const handleResendOtp = async () => {
    let optpType = localStorage.getItem("lastVisitedPage") === "/" ? 20 : 40
      let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        "OTPType":optpType,
        "IsEmailOTP":false
      };
      const valiadteNewOtp = await Axios.resendOTP(payload);
      if (valiadteNewOtp?.ReturnCode === 200) {
        toast.success("OTP Sent");
        localStorage.setItem("PhoneNumberOTPReferenceId",valiadteNewOtp.OTPReferenceId)
        setResentOtp((prev) => prev + 1)
      } else {
        // setOtpError(valiadteNewOtp?.toString());
        toast.error(valiadteNewOtp?.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
  }

  const ResendButton = () => (
    <Button
      variant="primary"
      className="color1 font-bold4 linkhover resend-btn inter-fstyle p-0"
      onClick={handleResendOtp}
    >
      Resend
    </Button>
  );
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state

      return <ResendButton />;
    } else {
      // Render a countdown
      return <span className="counter">{seconds}s</span>;
    }
  };

  const time = React.useMemo(() => {
    return Date.now() + 59000
}, [resentOtp]);

  // const mobile_number = localStorage.getItem("number");
  const [otp, setOtp] = useState("");
  
  const calculateAttempt = async () => {
    let payload = {
      UserReferenceId: localStorage.getItem("UserReferenceId"),
    };
    const remainingAttempt = await Axios.wrongPinAttempt(payload);
    if (remainingAttempt?.ReturnCode !== 200) {
      localStorage.setItem("isUserRestricted", "true");
      localStorage.setItem("lastVisitedPage", location.pathname);
      return -1
    }  
    return 1
  };

  const handleNavigate = async () => {
    if (otp === "") {
      // setOtpError("Please enter the email pin" );
      toast.error("Please enter the email pin", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    } else {
      setApiCallInProgress(true)
      let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
        OTP: otp,
      };
      const valiadteUser = await Axios.validateOTP(payload);
      if (valiadteUser?.ReturnCode === 200) {
        if (localStorage.getItem("lastVisitedPage") === "/accountrestricted") {
          let newPayload = {
            UserReferenceId: localStorage.getItem("UserReferenceId"),
            OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
            IsWeb: true,
            URL: `${process.env.REACT_APP_URL}/update?token=`,
          };
          const recoverPassword = await Axios.passwordRecovery(newPayload);
          // localStorage.setItem("lastVisitedPage", location.pathname);
          if (recoverPassword?.ReturnCode === 200) {
            localStorage.setItem("email", recoverPassword?.Email);
            // navigate("/resetpin");
            setApiCallInProgress(false)
            if (localStorage.getItem("lastVisitedPage") === "/") {
              navigate("/pin");
            } 
            else if (localStorage.getItem("lastVisitedPage") === "/update") {
              navigate("/updatepin")
            }
            else {
              // navigate("/updatepin");
              navigate("/resetpin");
            }
          } else {
            // setOtpError(recoverPassword?.toString());
            toast.error(recoverPassword?.toString(), {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 5000,
              className: "custom-toast"
            });
            setApiCallInProgress(false)
          }
        } else {
          if (localStorage.getItem("lastVisitedPage") === "/") {
            let payload = {
              UserReferenceId: localStorage.getItem("UserReferenceId"),
            };
            const remainingAttempt = await Axios.wrongPinAttempt(payload);
            if (remainingAttempt?.ReturnCode !== 200){
              localStorage.setItem("isUserRestricted", "true");
              localStorage.setItem("lastVisitedPage", location.pathname);
            }
            navigate("/pin");
          } 
          else if (localStorage.getItem("lastVisitedPage") === "/update") {
            navigate("/updatepin")
          }
          else {
            // navigate("/updatepin");
            navigate("/resetpin");
          }
          setApiCallInProgress(false)
        }
      } else {
        // setOtpError(valiadteUser?.toString());
        toast.error(valiadteUser?.response?.data?.Message.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
        setApiCallInProgress(false)
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enter Verification Code</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                {/* <Link to="/" className="back-icon-cover">
                  <MdArrowBackIosNew className="back-icon" />
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 evc-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Enter Verification Code
                  </h2>
                  <img src={star8} alt="" className="star8 evc-star8" />
                </div>
                <p className="font-bold3 authentication-card-sub-heading color1 inter-fstyle">
                  Please enter verification code sent to
                </p>
                <p className="font-bold4 authentication-card-sub-heading color1 inter-fstyle">
                  {localStorage.getItem('countrycode')} {mobile_number}
                </p>

                <form className="authentication-enter-verify-form authentication-form">
                  <div className="mb-2">
                    <Form.Label className="authentication-card-label color1 font-bold2 otp-label mb-3">
                    {localStorage.getItem('countrycode')} {mobile_number}
                    </Form.Label>
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value) => {
                        setOtp(value);
                      }}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      //  inputFocusStyle={{ borderColor: "blue" }}
                      onComplete={(value) => setOtp(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>

                  {/* <Row className="otp-row-cls">
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="1" className="authentication-card-input otp-input" />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="2" className="authentication-card-input otp-input" />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="3" className="authentication-card-input otp-input" />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="4" className="authentication-card-input otp-input" />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="5" className="authentication-card-input otp-input" />
                          </Col>
                          <Col xs={2}>
                            <Form.Control type="text" placeholder="6" className="authentication-card-input otp-input" />
                          </Col>
                        </Row> */}

                  <div className="mb-0 inter-fstyle font-bold3 color1 text-end">
                    <Countdown date={time} key={resentOtp} renderer={renderer}/>
                  </div>
                  {
                    otpError.length?
                    <p className="red cc-error-msg mb-0 mt-1 text-center">{otpError}</p>
                    :
                    null
                  }
                  {/* {
                    resentOtp?
                    <div className="mb-0 inter-fstyle font-bold3 color1 text-end">
                    <Countdown date={time1} renderer={renderer1} />
                  </div>
                  :
                  <div className="mb-0 inter-fstyle font-bold3 color1 text-end">
                    <Countdown date={time} renderer={renderer} />
                  </div>
                  } */}
                  <div className="text-center">
                    {/* <div className="d-flex align-items-center justify-content-center">
                               <p className="mb-0 inter-fstyle font-bold3 color1">Didn’t receive a code?</p>
                               <Button variant="primary" className="color1 font-bold4 linkhover resend-btn inter-fstyle fs-6">Resend</Button>
                           </div> */}
                    {/* <Link to="/pin"> */}
                    <Button
                      variant="primary"
                      className="authentication-button evc-btn mb-4"
                      disabled={(otp === "" || apiCallInProgress) ? true : false}
                      onClick={handleNavigate}
                    >
                      Verify
                    </Button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
