import {SETUSER,SETFIATDETAILS,SETCRYPTOBALANCE,UPDATEKYCDETAILS,SETPROFILEDETAILS,CHANGEPORTFOLIOVISIBILITY,RESETUSER} from './userActionTypes'

const initState = {
    email:'',
    number:0,
    countrycode:'',
    firstName:'',
    lastName:'',
    kycStatus:'',
    kycRejectionReasons:'',
    portfolioVisibilityStatus:true,
    profileDetails:{},
    fiatDetails:{},
    cryptoBalance:{}
}

export const userReducer = (state:any=initState,action:any) =>{
  switch(action.type){
    case SETUSER: return{
       ...state,
        email: action.payload.email,
        number: action.payload.number,
        countrycode: action.payload.countrycode, 
        firstName:action.payload.firstName,
        lastName:action.payload.lastName,
        kycStatus:action.payload.kycStatus,
        kycRejectionReasons:action.payload.kycRejectionReasons
    }
    case SETFIATDETAILS: 
        return{
          ...state,
          fiatDetails: action.payload, 
        }
    case SETCRYPTOBALANCE: return{
        ...state,
        cryptoBalance:action.payload,
    }    
    case UPDATEKYCDETAILS: return{
        ...state,
        kycStatus:action.payload.kycStatus,
        kycRejectionReasons:action.payload.kycRejectionReasons

    }
    case SETPROFILEDETAILS: return{
      ...state,
      profileDetails:action.payload, 
    }
    case CHANGEPORTFOLIOVISIBILITY: return{
      ...state,
      portfolioVisibilityStatus:action.payload
    }
    case RESETUSER: return{
      ...state,
        email:'',
        number:0,
        countrycode:'',
        firstName:'',
        lastName:'',
        kycStatus:'',
        kycRejectionReasons:'',
        portfolioVisibilityStatus:true,
        profileDetails:{},
        fiatDetails:{},
        cryptoBalance:{}    
    }
    default:
    return state
  }
}