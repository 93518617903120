import { Navigate,useLocation } from "react-router-dom";

 const ProtectedRoute = ({ children }: any) => {
  const location=useLocation()
  let userToken = localStorage.getItem("token");
    
    if (userToken) {
      let documentCheck = localStorage.getItem("isDocumentsRequired")
      // console.log("documentCheck",documentCheck,location.pathname)
      if(documentCheck=== 'true'){
          if(`/documentRestrictions` === location.pathname){
            return children
          }
          else{
            return <Navigate to="/documentRestrictions" />
          }
      }else{
        return children;
      }
      
    }else {
      return <Navigate to="/" />;
      }
  
};

export default ProtectedRoute
