import {SETBANKLIST,RESETBANKLIST} from './bankActionTypes'

export const setBankList = (bankList:any) =>{
    return{
        type:SETBANKLIST,
        payload:bankList
    }
}

export const resetBankList = () =>{
    return{
        type:RESETBANKLIST
    }
}