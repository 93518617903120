import React,{useEffect,useState} from "react";
import "../../App.css";
import "../../components/SignIn/SignIn.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import email from "../../assets/images/email.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function ResetPin() {
  // const user_email = localStorage.getItem("email");
  const [user_email,setEmail] = useState('')
  const navigate =useNavigate()

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('email')

    if(requiredNumber){
      setEmail(requiredNumber)
    }
    else{
      navigate('/')
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset PIN</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card h-auto h-auto m-auto border-gr pb-5">
              <div className="card-body position-relative">
                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 evc-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Reset PIN
                  </h2>
                  <img src={star8} alt="" className="star8 evc-star8" />
                </div>
                <img
                  src={email}
                  alt=""
                  className="d-flex m-auto mt-5 mb-3 mb-sm-0 responsive-img"
                />
                <h6 className="fs-5 mb-3 text-center inter-fstyle color1 font-bold4 resetinfo-heading">
                  Safe link sent via email
                </h6>
                <p className="font-bold3 authentication-card-sub-heading2 inter-fstyle color1">
                  Link to reset PIN has been sent to your registered Email id.
                </p>
                <p className="font-bold4 resetpin-heading color1 inter-fstyle text-center">
                  {user_email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
