import {FC} from 'react'
import { Circles,RotatingLines } from  'react-loader-spinner'

interface LoadingTypes{
    type:string
}

const Loading:FC<LoadingTypes> = (props):JSX.Element =>{

    return(
        <>
        {
        props.type === 'pageLoader'?    
         <Circles
                height="80"
                width="80"
                color="#E4E4F0"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass="lazy-loading-circle"
                visible={true}
              />     
            :
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        }
        </> 
    )
}

export default Loading