import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../components/Signup/Signup.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import PinInput from "react-pin-input";
import { toast } from "react-toastify";
import * as Axios from "../../utils/API";
import { Helmet } from "react-helmet-async";

export default function VerifyYourAccount() {
  const [mobilePin, setMobilePin] = useState("");
  const [emailPin, setEmailPin] = useState("");

  const [mobilePinVerified, setMobilePinVerified] = useState(false);
  const [emailPinVerified, setEmailPinVerified] = useState(false);
  const [resentOtp, setResentOtp] = useState<number>(0);
  const [resentOtp1, setResentOtp1] = useState<number>(0);
  const [number,setMobileNumber] = useState('')
  const [apiCallInProgress,setApiCallInProgress] = useState(false)
  const [email,setEmail] = useState('')


  useEffect(()=>{
  },[resentOtp, resentOtp1])

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredEmail = localStorage.getItem('email')
    let requiredNumber = localStorage.getItem('number')
    if( requiredEmail && requiredNumber){
      setMobileNumber(requiredNumber)
      setEmail(requiredEmail)
    }
    else{
      navigate('/')
    }
  }

  const navigate = useNavigate();
  const location = useLocation();

  const handleResendOtp = async () => {
    //let optpType = localStorage.getItem("lastVisitedPage") === "/signup" ? 10 : 20
      let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        //EmailOTPReferenceId: localStorage.getItem("EmailOTPReferenceId"),
        "OTPType": 10,
        "IsEmailOTP": false,
      };
      const valiadteNewOtp = await Axios.resendOTP(payload);
      if (valiadteNewOtp?.ReturnCode === 200) {
        toast.success("OTP Sent");
        setResentOtp((prev) => prev + 1);
      } else {
        toast.error(valiadteNewOtp?.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
  };

  const handleResendOtp1 = async () => {
    //let optpType = localStorage.getItem("lastVisitedPage") === "/signup" ? 10 : 20
    let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        //EmailOTPReferenceId: localStorage.getItem("EmailOTPReferenceId"),
        "OTPType":10,
        "IsEmailOTP":true
      };
      const valiadteNewOtp = await Axios.resendOTP(payload);
      if (valiadteNewOtp?.ReturnCode === 200) {
        // toast.success("OTP Sent");
        setResentOtp1((prev) => prev + 1);
      } else {
        toast.error(valiadteNewOtp?.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
  };

  // Random component
  const ResendButton = () => (
    <Button
      variant="primary"
      className="color1 font-bold4 linkhover resend-btn inter-fstyle p-0"
      onClick={handleResendOtp}
    >
      Resend
    </Button>
  );

  const ResendButton1 = () => (
    <Button
      variant="primary"
      className="color1 font-bold4 linkhover resend-btn inter-fstyle p-0"
      onClick={handleResendOtp1}
    >
      Resend
    </Button>
  );

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state

      return <ResendButton />;
    } else {
      // Render a countdown
      return <span>{seconds}s</span>;
    }
  };

  const renderer1 = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state

      return <ResendButton1 />;
    } else {
      // Render a countdown
      return <span>{seconds}s</span>;
    }
  };

  const time = React.useMemo(() => {
    return Date.now() + 59000;
  }, [resentOtp]);

  const time1 = React.useMemo(() => {
    return Date.now() + 59000;
  }, [resentOtp1]);

  const handleOTPverification = async (type: string,otp:string) => {
    let payload = {
      ReferenceId: localStorage.getItem("UserReferenceId"),
      OTPReferenceId:
        type === "mobile"
          ? localStorage.getItem("PhoneNumberOTPReferenceId")
          : localStorage.getItem("EmailOTPReferenceId"),
      OTP: otp,
    };
    const validateOTP = await Axios.validateOTP(payload);
    localStorage.setItem("lastVisitedPage", location.pathname);
    if (validateOTP?.ReturnCode === 200) {
      // toast.success(type === "mobile" ? "Mobile Pin Varified" : "Email Pin Varified");
      return true;
    } 
  };

  const functionMobileValidate = async(otp:string) =>{
    if (otp.length === 6 && !mobilePinVerified) {
      let result = await handleOTPverification("mobile",otp);
      if (result) {
        setMobilePinVerified(true);
      }
    }
  }

  const functionEmailValidate = async(otp:string) =>{
    if (otp.length === 6 && !emailPinVerified) {
      let result = await handleOTPverification("email",otp);
      if (result) {
        setEmailPinVerified(true);
      }
    }
  }

  const handleNavigate = async () => {
    if (!mobilePin.length) {
      // alert("Please enter the mobile number");
      toast.error("Please enter the mobile verification code.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      })
    } else if (!emailPin.length) {
      // alert("Please enter the mobile number");
      toast.error("Please enter the email verification code.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      })
    } else {
      setApiCallInProgress(true)
        let payload = {
          UserReferenceId: localStorage.getItem("UserReferenceId"),
          EmailOTPReferenceId: localStorage.getItem("EmailOTPReferenceId"),
          PhoneNumberOTPReferenceId: localStorage.getItem(
            "PhoneNumberOTPReferenceId"
          ),
        };
        const activateUserAccount = await Axios.accountActivation(payload);
        if (activateUserAccount?.ReturnCode === 200) {
          localStorage.setItem(
            "UserReferenceId",
            activateUserAccount?.UserReferenceId
          );
          localStorage.setItem("Token", activateUserAccount?.Token);
          setApiCallInProgress(false)
          navigate("/pin");
        } else {
          setApiCallInProgress(false)
          toast.error(activateUserAccount?.toString(), { // activateUserAccount?.toString(), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            className: "custom-toast"
          })
        }
      } 
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verify your account</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                {/* <Link to="/Signup" className="back-icon-cover">
                  <MdArrowBackIosNew className="back-icon" />
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 otp-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Verify your account
                  </h2>
                  <img src={star8} alt="" className="star8 otp-star8" />
                </div>
                <p className="font-bold3 authentication-card-sub-heading color1 inter-fstyle">
                  Please enter verification code sent to
                </p>
                <p className="font-bold4 authentication-card-sub-heading color1 inter-fstyle">
                {localStorage.getItem('countrycode')} {number} &{" "}
                  {email}
                </p>

                <form className="authentication-verify-form authentication-form">
                  {/* <Row className="otp-row-cls mb-4">
                      <Form.Label className="authentication-card-label color1 font-bold2 otp-label">
                        +91 {number}
                      </Form.Label>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                    </Row> */}
                  <div className="mb-1">
                    <Form.Label className="authentication-card-label color1 font-bold2 otp-label mb-3">
                    {localStorage.getItem('countrycode')} {number}
                    </Form.Label>
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {}}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      //  inputFocusStyle={{ borderColor: "blue" }}
                      onComplete={(value, index) => {
                        setMobilePin(value)
                        functionMobileValidate(value)
                      }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    <div className="mb-0 inter-fstyle font-bold3 color1 mt-2 text-end">
                      <Countdown
                        date={time}
                        key={resentOtp}
                        renderer={renderer}
                      />
                    </div>
                  </div>
                  <div>
                    <Form.Label className=" color1 font-bold2 otp-label mb-3">
                      {email}
                    </Form.Label>
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {}}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      //  inputFocusStyle={{ borderColor: "blue" }}
                      onComplete={(value, index) => {
                        setEmailPin(value);
                        functionEmailValidate(value)
                      }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>
                  {/* <Row className="otp-row-cls">
                      <Form.Label className="authentication-card-label color1 font-bold2 otp-label">
                        {localStorage.getItem("email")}
                      </Form.Label>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          placeholder="1"
                          className="authentication-card-input otp-input"
                        />
                      </Col>
                    </Row> */}
                  <div className="text-center mb-2">
                    <div className="inter-fstyle font-bold3 color1 mt-2 text-end">
                      <Countdown
                        date={time1}
                        key={resentOtp1}
                        renderer={renderer1}
                      />
                      {/* <div className="d-flex align-items-center justify-content-center mb-4"> */}
                      {/* <p className="mb-0 inter-fstyle font-bold3 color1">
                          Didn’t receive a code?
                        </p> */}

                      {/* <Button variant="primary" className="color1 font-bold4 linkhover resend-btn inter-fstyle fs-6">Resend</Button> */}
                    </div>
                    {/* </div> */}
                    {/* <Link to="/verificationsuccesful"> */}
                    <Button
                      variant="primary"
                      className="authentication-button mt-4"
                      disabled={
                        ((mobilePin === "" || emailPin === "")|| apiCallInProgress) ? true : false
                      }
                      onClick={handleNavigate}
                    >
                      Verify
                    </Button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
