import React, { useEffect } from "react";
import '../../App.css';
import '../../components/Signup/Signup.css';
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import verify from "../../assets/images/verify.svg";
import Button from 'react-bootstrap/Button';

import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

export default function VerificationSuccesful() {


  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("lastVisitedPage", location.pathname);
  }, [])
  return (
       <>
          <Helmet>
        <meta charSet="utf-8" />
        <title>Verification Successful</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
       <section className="vh-100 signup-section section" >
          <div className="container">
            <div className="common-gradient-bg"> </div>
            <div className="text-center py-1">
               <img src={LOGO} alt="" className="logo-img" />
            </div>
              <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="card authentication-card  m-auto border-gr">
                  <div className="card-body position-relative">
                    {/* <Link to="/enterverificationcode" className="back-icon-cover">
                      <MdArrowBackIosNew className="back-icon"/>
                    </Link> */}
                     
                      <div className="authentication-card-t-box">
                          <img src={star9} alt="" className="star9 otp-star9"/>
                          <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">Verification Successful</h2>
                          <img src={star8} alt="" className="star8 otp-star8"/>
                      </div>
                      <div className="verify-section text-center mb-5">
                         <img src={verify} alt="" className="responsive-img" />
    
                          <p className="font-bold2 authentication-card-sub-heading color1 poppins-fstyle mt-5 text-capitalize">Account verification done successfully.</p>
                          
    
                          <Link to="/">
                            <Button variant="primary" className="authentication-button verify-btn">Log In</Button>
                          </Link>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
       </>
  );
}
