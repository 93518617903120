import React,{useState,useEffect} from "react";
import "../../App.css";
import "../../components/Signup/Signup.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import greencheck from "../../assets/images/green-check.svg";
import cancel from "../../assets/images/cancel.svg";
import Shield from "../../assets/images/shield-check.svg";
import Button from "react-bootstrap/Button";

import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Axios from "../../utils/API";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from "react-google-recaptcha";

export default function ResetPinInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [captchaToken,setCaptchaToken] = useState<string | null>(null)
  const [verified, setVerified] = useState(false);
  const [captchError,setCaptchError] = useState({status:false, message:""})
  const [number,setMobileNumber] = useState('')
  const [apiCallInProgress,setApiCallInProgress] = useState(false)
  const [captchaSiteKey,SetSiteKey] = useState(process.env.REACT_APP_SITE_KEY)

  // const [recaptchaRef,setRecaptchaRef] = useState<any>()

  // useEffect(()=>{
    // setRecaptchaRef(React.createRef<ReCAPTCHA>());
  // },[])

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('number')

    if(requiredNumber){
      setMobileNumber(requiredNumber)
    }
    else{
      navigate('/')
    }
  }

  // let number: any = localStorage.getItem("number");
  let countrycode: any = localStorage.getItem("countrycode");
  let lastLocation: any = localStorage.getItem("lastVisitedPage")?.toString();

  if (lastLocation === "/accountrestricted") {
    lastLocation = "/";
  } else {
    lastLocation = "/signup";
  }

  

  const handleNavigate = async () => {

    if(!captchaToken){
      return setCaptchError({status:true,message:"Captcha should be validated."})
    }
    setApiCallInProgress(true)
    let payload = {
      PhoneNumber: number.toString(),
      CountryCode: countrycode.toString(),
      ReCaptchaToken:captchaToken
    };
    const loginUser = await Axios.login(payload);
    if (loginUser?.ReturnCode === 200) {
      localStorage.setItem("number", number.toString());
      localStorage.setItem("UserReferenceId", loginUser?.UserReferenceId);
      localStorage.setItem(
        "PhoneNumberOTPReferenceId",
        loginUser?.PhoneNumberOTPReferenceId
      );
      // localStorage.setItem("lastVisitedPage", location.pathname.toString());
      if (localStorage.getItem("lastVisitedPage") === "/accountrestricted") {
        navigate("/enterverificationcode");
      } else {
        navigate("/forgotpin");
      }
      setApiCallInProgress(false)
    } else {
      // alert("Some error occured");
      setApiCallInProgress(false)
      toast.error(loginUser?.toString(), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    }
  };

  const recaptchaRef = React.createRef<ReCAPTCHA>();
  

  function onChange(value: any) {
    setCaptchaToken(value)
    setVerified(true);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset PIN</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative reset-body">
                {/* <Link to="/enterverificationcode" className="back-icon-cover">
                  <MdArrowBackIosNew className="back-icon" />
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 otp-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4 mb-2">
                    Reset PIN
                  </h2>
                  <img src={star8} alt="" className="star8 otp-star8" />
                </div>
                <div className="text-center">
                  <img src={Shield} alt="" className="responsive-img" />

                  <p className="font-bold4 authentication-card-sub-heading color1 poppins-fstyle mt-3 text-capitalize">
                    For the purpose of your account safety
                  </p>

                  <div className="row mt-3">
                    <div className="col text-start">
                      <p className="poppins-fstyle color1 font-bold3">
                        you can only
                      </p>
                      <span className="info-box">
                        <img
                          src={greencheck}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle mb-0">
                          Buy/Sell Coins
                        </h6>
                      </span>
                      <span className="info-box">
                        <img
                          src={greencheck}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle mb-0">
                          Receive Coins
                        </h6>
                      </span>
                      <span className="info-box">
                        <img
                          src={greencheck}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle mb-0">
                          Place deposit orders
                        </h6>
                      </span>
                    </div>
                    <div className="col text-start">
                      <p className="poppins-fstyle color1 font-bold3">
                        you cannot (For 24hrs)
                      </p>

                      <span className="info-box">
                        <img
                          src={cancel}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle">Send Coin</h6>
                      </span>
                      {/* <span className="info-box">
                        <img
                          src={cancel}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle">
                          Change lock for outgoing transactions
                        </h6>
                      </span> */}

                      <span className="info-box">
                        <img
                          src={cancel}
                          alt=""
                          className="responsive-col-img"
                        />
                        <h6 className="color1 poppins-fstyle">
                          Place withdrawal order{" "}
                        </h6>
                      </span>
                    </div>
                  </div>

                  <form className="recaptcha d-flex justify-content-center mt-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={captchaSiteKey?captchaSiteKey:''} 
                        onChange={onChange}
                        theme="dark"
                        onExpired={() => {
                          setVerified(false);
                          if (recaptchaRef.current) {
                            recaptchaRef.current.reset();
                          }
                        }}
                      />
                    <p className={captchError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{captchError.message}</p>
                    </form>    


                  <div className="d-flex gap-4 justify-content-center reset-btns mb-3 mt-4">
                    <Button
                      variant="outline-secondary"
                      className="reset-cancel-btn inter-fstyle"
                      size="sm"
                      onClick={() => navigate("/")}
                    >
                      Cancel
                    </Button>
                    {/* <Link
                      to="/enterverificationcode"
                      role="button"
                      className="btn btn-primary reset-con-btn inter-fstyle"
                      onClick={handleNavigate}
                    >
                      Continue
                    </Link> */}
                    {/* <Link to="/enterverificationcode">  */}
                    <Button
                      variant="primary"
                      className="reset-con-btn inter-fstyle btn-blue-hover"
                      size="sm"
                      disabled={!verified || apiCallInProgress}
                      onClick={handleNavigate}
                    >
                      Continue
                    </Button>
                    {/* </Link>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
