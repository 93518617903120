import {SETCOINLIST,RESETCOINLIST} from './coinListActionTypes'


const initState = {
    coinList:[]
}

export const coinListReducer = (state:any=initState,action:any) =>{
  switch(action.type){
    case SETCOINLIST: return{
        coinList: action.payload, 
    }
    case RESETCOINLIST: return{
      ...state,
      coinList: [], 
  }
    default: return state
  }
}