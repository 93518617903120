import React, { useEffect, useState,lazy,Suspense } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "./redux";
import { RootState } from "./redux/reducers";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Signup from "./Pages/Signup";
import SignIn from "./Pages/SignIn";
import { getToken } from "firebase/messaging";
import {messaging,onMessageListener} from "./utils/firebase";
import Emitter from "./utils/emitter";
import VerifyYourAccount from "./components/Signup/VerifyYourAccount";
import EnterVerificationCode from "./components/SignIn/EnterVerificationCode";
import Pin from "./components/SignIn/Pin";
import ForgotPin from "./components/SignIn/ForgotPin";
import ResetPin from "./components/SignIn/ResetPin";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "./utils/ScrollToTop";
import ResetPinInfo from "./components/SignIn/ResetPinInfo";
import VerificationSuccesful from "./components/Signup/VerificationSuccesful";
import PinChanged from "./Pages/PinChanged";
import AccountRestricted from "./Pages/AccountRestricted";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Update from "./Pages/Update";
import UpdatePin from "./components/UpdatePin/UpdatePin";
import { HelmetProvider } from 'react-helmet-async';
import { AuthRoute } from "./AuthRoute"
import ProtectedRoute from './ProtectedRoute'
import * as Axios from "./utils/API";
import Loading from './components/Loading'

const Home = lazy(()=>import('./Pages/Home'))
const Deposit = lazy(()=>import('./components/Home/Deposit/Deposit'))
const Withdraw = lazy(()=>import('./components/Home/Withdraw/Withdraw'))
const AddAddress = lazy(()=>import('./components/Home/SendCoinAddress/AddAddress'))
const ReceiveAddress = lazy(()=>import('./components/Home/ReceiveCoinAddress/ReceiveAddress'))
const CryptoHistory = lazy(()=>import('./components/Home/CryptoTransactions/CryptoHistory'))
const Addbank = lazy(()=>import('./components/Home/AddBank/Addbank'))
const Portfolio = lazy(()=>import('./components/Home/Portfolio/Portfolio'))
const Wallet = lazy(()=>import('./components/Home/Wallet'))
const BuySell = lazy(()=>import('./components/Home/SpotTrade/BuySell'))
const SpotTradeHistory = lazy(()=>import('./components/Home/SpotTrade/SpotTradeHistory'))
const SuccessfulKyc = lazy(()=>import('./components/Home/KYC/SuccessfulKyc'))
const StartKyc = lazy(()=>import('./components/Home/KYC/StartKyc'))
const UpdatePan = lazy(()=>import('./components/Home/PanCard/UpdatePan'))
const FiatTransaction = lazy(()=>import('./components/Home/CryptoTransactions/FiatTransaction'))
const Transactions = lazy(()=>import('./components/Home/Transactions'))
const DocumentRestricted = lazy(()=>import('./Pages/DocumentRestricted'))

function App() {

  useEffect(()=>{
    Emitter.on('notificationEnabler',()=>{
      requestPermission()
    })
  },[])

  window.onload = function () {
    let session = sessionStorage.getItem('register');

    if (session == null) {
        localStorage.clear()
    }

    sessionStorage.setItem('register', 'true');
  }

  async function requestPermission() {
    try{
        const permission = await Notification.requestPermission()
        if (permission === "granted"){
          
          const token = await getToken(messaging, { vapidKey: process.env.REACT_App_vapidKey});
          let payload = {
            FCMToken:token,
            DeviceId:navigator.userAgent,
            Platform:'web'
          }
          await Axios.storeFcmToken(payload)
        } else if (permission === "denied") {
          //alert("You denied for the notification.")
          console.log("User denied for the notification.")
        }
      }
    catch(error){
       console.log("error",error) 
    }
  }
 
  onMessageListener().then(payload => {
    const payloadNotification: any = payload
    // console.log("payload==",payload);    
    // console.log("payloadNotification=="y,payloadNotification);
    // console.log("notification==",payloadNotification.notification);
    // console.log("data==",payloadNotification.data);
    //console.log(payload);
  }).catch(err => console.log('failed: ', err));


  return (
    <div>
      <HelmetProvider>
      <BrowserRouter>
        {/* Prevent page rendring from middle */}
        <ScrollToTop />
        {/* check whether user is restricted or not */}
        {/* <CheckUserRestriction /> */}
        <AnimatePresence>
        <Suspense fallback={<Loading type='pageLoader' />}>  
          <Routes>
            {/* <Route path="/" element={<Splash /> } /> */}
            <Route path="/home"  element={<ProtectedRoute> <Home /></ProtectedRoute>} />
            <Route path="/signup" element={<AuthRoute> <Signup /></AuthRoute> } />
            <Route path="/" element={<AuthRoute><SignIn /></AuthRoute>} />
            <Route path="/forgotpin" element={<AuthRoute> <ForgotPin /></AuthRoute> } />
            <Route path="/update" element={<AuthRoute> <Update /></AuthRoute>} />
            <Route path="/verifyouraccount" element={<AuthRoute><VerifyYourAccount /></AuthRoute> } />
            <Route
              path="/enterverificationcode"
              element={<AuthRoute> <EnterVerificationCode /></AuthRoute> }
            />
            <Route path="/pin" element={<AuthRoute><Pin /> </AuthRoute> } />
            <Route path="/resetpin" element={<AuthRoute><ResetPin /> </AuthRoute> } />
            <Route path="/resetpininfo" element={<AuthRoute><ResetPinInfo /></AuthRoute> } />
            <Route path="/updatepin" element={<AuthRoute><UpdatePin /></AuthRoute> } />
            <Route path="/pinchanged" element={<AuthRoute><PinChanged /></AuthRoute> } />
            <Route path="/accountrestricted" element={<AuthRoute><AccountRestricted /></AuthRoute> } />
            <Route
              path="/verificationsuccesful"
              element={<AuthRoute><VerificationSuccesful /></AuthRoute> }
            />
            {/* <Route path="/spottrade" element={<SpotTrade />} /> */}
            <Route path="/deposit" element={<ProtectedRoute><Deposit /></ProtectedRoute> } />
            <Route path="/withdraw" element={<ProtectedRoute><Withdraw /></ProtectedRoute>} />
            <Route path="/send" element={<ProtectedRoute><AddAddress /></ProtectedRoute>} />
            <Route path="/receive" element={<ProtectedRoute><ReceiveAddress /></ProtectedRoute>} />
            <Route path="/cryptoHistory" element={<ProtectedRoute><CryptoHistory /></ProtectedRoute>} /> 
            <Route path="/bank" element={<ProtectedRoute><Addbank /></ProtectedRoute>} />
            <Route path="/porfolio" element={<ProtectedRoute><Portfolio /></ProtectedRoute>} />
            <Route path="/myAssets" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
            <Route path="/spotTrade" element={<ProtectedRoute><BuySell /></ProtectedRoute>} /> 
            <Route path="/spotTradeHistory" element={<ProtectedRoute><SpotTradeHistory /></ProtectedRoute>} />
            <Route path="/kycStatus" element={<ProtectedRoute><SuccessfulKyc /></ProtectedRoute>} />
            <Route path="/kycStart" element={<ProtectedRoute><StartKyc /></ProtectedRoute>} />
            <Route path="/panCard" element={<ProtectedRoute><UpdatePan /></ProtectedRoute> } />
            <Route path="/fiatHistory" element={<ProtectedRoute><FiatTransaction /></ProtectedRoute> } />
            <Route path="/transactions" element={<ProtectedRoute> <Transactions /></ProtectedRoute> } />
            <Route path="/documentRestrictions" element={<ProtectedRoute><DocumentRestricted /></ProtectedRoute> } />

            </Routes>
        </Suspense>    
            
          </AnimatePresence>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
