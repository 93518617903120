import axios from 'axios'
import {SETUSER,SETFIATDETAILS,SETCRYPTOBALANCE,UPDATEKYCDETAILS,SETPROFILEDETAILS,CHANGEPORTFOLIOVISIBILITY,RESETUSER} from './userActionTypes'
import { findCurrency } from '../../utils/helper'

export const setUser = (userDetails:{email:string,number:number,countrycode:string}) =>{
    return{
        type:SETUSER,
        payload:userDetails
    }
}

export const setUserProfileDetails = (profileDetails:any) =>{
    return{
        type:SETPROFILEDETAILS,
        payload:profileDetails
    }
}

export const setUserFiatDetails = (fiatData:any) =>{
    return{
        type:SETFIATDETAILS,
        payload:fiatData
    }
}

export const setCryptoBalance = (cryptoBalance:any) =>{
    return{
        type:SETCRYPTOBALANCE,
        payload:cryptoBalance
    }
}

export const updateKycDetails = (kycDetails:any)=>{
    return{
        type:UPDATEKYCDETAILS,
        payload:kycDetails
    }   
}

export const changePortfolioVisibilityStatus = (changeStatus:boolean) =>{
    return{
        type:CHANGEPORTFOLIOVISIBILITY,
        payload:changeStatus
    }
}

export const resetUser = () =>{
    return {
       type:RESETUSER
    }   
}

export const fetchUserBalance = () =>{
    return async(dispatch:any)=>{
        try{
        let headers = {"Accept": "application/json",
                        "Content-Type": "application/json",};
        let requiredCurrency = findCurrency()
        const DEFAULT_HEADER = { headers: { ...headers,"Authorization": localStorage.getItem("token") } };
        const API_ENDPOINT = `${process.env.REACT_APP_SERVER_URL}/api` + `/getfiatbalance?currencyType=${requiredCurrency}`;
        let apiResponse =  await axios.get(API_ENDPOINT, DEFAULT_HEADER)  
        dispatch(setUserFiatDetails(apiResponse.data))
        }
        catch(error:any){
            console.log("error",error)    
        }
    }
}