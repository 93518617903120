import api from './AxiosSetup/AxiosInstance'
import formatDataApi from './AxiosSetup/AxiosFormDataInstance'
import { findCurrency } from './helper';


const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api`;
let interval:any

export function registration(payload: any) {
  const API_ENDPOINT = BASE_URL + `/register`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      return response.data;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export function login(payload: any) {
    const API_ENDPOINT = BASE_URL + `/login`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }

  export async function validateOTP(payload: any) {
    try{
    
    const API_ENDPOINT = BASE_URL + `/validateOTP`;
    let response =  await api.post(API_ENDPOINT, payload)
    return response.data
    }
    catch(error){
      console.log("err", error)
      return error
    }
  }

  export function validatePIN(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/validatePIN`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {    
        console.log("err", err);
        return err?.response?.data;
      }
    );
  }

  export function resendOTP(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/resendOTP`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }
  
  export async function listCountryCode() {
    const API_ENDPOINT = BASE_URL + `/listcountrycode`;
    return await api.get(API_ENDPOINT)
  }

  export const bankCountryList = async()=>{
    const API_ENDPOINT = BASE_URL + '/supportedbankcountrylist' 
    return await api.get(API_ENDPOINT)
  }
  
  export function accountActivation(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/accountactivation`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }

  export function passwordRecovery(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/passwordrecovery`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }

  export function passwordRecoveryComfirm(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/passwordrecoveryconfirm`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }


  export function wrongPinAttempt(payload: any) {
    
    const API_ENDPOINT = BASE_URL + `/remainwrongPINattempt`;
    return api.post(API_ENDPOINT, payload).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }

  export function getBankListApi() {
    const API_ENDPOINT = BASE_URL + `/listbank`;
    return api.get(API_ENDPOINT).then(
      (response) => {
        return response.data;
      },
      (err) => {
        console.log("err", err);
        return err?.response?.data?.Message;
      }
    );
  }

export const uploadDocument = (payload:any) =>{
  const API_ENDPOINT = BASE_URL + `/documentupload`;
  return formatDataApi.post(API_ENDPOINT, payload).then(
    (response) => {
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}  

export function addNewBankAccount(payload: any) {
  const API_ENDPOINT = BASE_URL + `/addbank`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export const editBankAccount = async(payload: any) =>{
  const API_ENDPOINT = BASE_URL + `/editbank`;
   return await api.post(API_ENDPOINT, payload)
  //  .then(
  //   (response) => {
  //     return response;
  //   },
  //   (err) => {
  //     console.log("err", err);
  //     return err?.response?.data?.Message;
  //   }
  // );
}

export const removeBank = (payLoad:any) =>{
  const API_ENDPOINT = BASE_URL + `/removebank`;
  return api.post(API_ENDPOINT, payLoad)
  .then(
    (response) => {
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

//---------------------------------------------------------------------
export const updateToken = (status:boolean) =>{
  if(status){ 
  interval = setInterval(async()=>{

  const API_ENDPOINT = BASE_URL + `/refreshtoken`;
  // let refreshToken = localStorage.getItem("refreshToken")      
  let _userInfo =  localStorage.getItem('userInfo') 
  let refreshToken:any
  if(_userInfo){
    let userInfo = JSON.parse(_userInfo)    
    refreshToken = userInfo.RefreshToken 
  }
  api.post(API_ENDPOINT, {RefreshToken:refreshToken }).then(
    (response) => {
      // console.log(response.data);
      localStorage.setItem('userInfo',JSON.stringify(response.data))
      localStorage.setItem("userInfo", JSON.stringify(response.data))
      localStorage.setItem("token", response.data.Token)
      localStorage.setItem("refreshToken", response.data.RefreshToken)
    },
    (err) => {
      console.log("err", err);
      // return err?.response?.data?.Message;
    }
  );     
  },10000)
  }
  else{
    clearInterval(interval)
  }
}

//deposit api's

export const getFiatBalance = () =>{
  let currency = findCurrency()
  const API_ENDPOINT = BASE_URL + `/getfiatbalance?currencyType=${currency}`;
  return api.get(API_ENDPOINT).then(
    (response) => {
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  ); 
}

export const getUnprocessedRequests = async() =>{
  // try{

  const API_ENDPOINT = BASE_URL + `/getunprocessfiatdepositrequest`;
  let apiResponse = await api.get(API_ENDPOINT)
  return apiResponse
  // }
  // catch(error:any){
  //     console.error(error?.response?.data?.Message);
  //     return error
  // }
}

export const getCryptoFees = async(currencyType:String) =>{

  const API_ENDPOINT = BASE_URL + `/getcryptofees?currencyType=${currencyType}` 
  // +currencyType;
  let apiResponse = await api.get(API_ENDPOINT)
  return apiResponse
}

export const getCryptoHistoryData =async(params:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/getcryptohistory?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&CurrencyType=${params.CurrencyType}`;
  let apiResponse = await api.get(API_ENDPOINT)
  return apiResponse
}

export const depositRequest = async(payload:{BankReferenceId:String, Amount:String}) =>{
  

  const API_ENDPOINT = BASE_URL + `/fiatdepositrequest`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data
    }
  );   
}

export const uploadReceipt = (payload:{DepositOrderNo:string,SystemBankReferenceId:string,DocumentReferenceId:string}) =>{
  

  const API_ENDPOINT = BASE_URL + `/uploadbankpaymentreceipt`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data;
    }
  );
}

export const orderCancelRequest = (DepositOrderNo:string) =>{
  

  const API_ENDPOINT = BASE_URL + `/cancelfiatdepositrequest`;
  return api.post(API_ENDPOINT, {DepositOrderNo}).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data;
    }
  );   
}


export const withdrawRequest = async(payload:{BankReferenceId:String, Amount:String}) =>{
  

  const API_ENDPOINT = BASE_URL + `/fiatwithdrawrequest`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data;
    }
  );
}

//sender 
export const withrawAddressAddRequest = (payload:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/addwithdrawaddress`;
  return api.post(API_ENDPOINT,payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export const getWithdrawAddresses = (token:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/listwithdrawaddress?CurrencyType=`+token;
  return api.get(API_ENDPOINT).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export const getWidrawlAddressDetails = (payload:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/getwithdrawaddress`;
  return api.post(API_ENDPOINT,payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export const withdrawRequestFinal = (payload:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/cryptowithdrawrequest`;
  return api.post(API_ENDPOINT,payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data;
    }
  );
}

export const calculateAttempt = (payload:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/remainwrongPINattempt`;
  return api.post(API_ENDPOINT,payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export const validatePINWithToken = (payload:any) =>{
  

  const API_ENDPOINT = BASE_URL + `/validatePINwithtoken`;
  return api.post(API_ENDPOINT,payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
} 

export const getCryptoBalance = async(CurrencyCode:any) =>{
  

  let API_ENDPOINT
  if(CurrencyCode){
  API_ENDPOINT = BASE_URL + `/getcryptobalance?CurrencyType=${CurrencyCode}`;
  }
  else{
    API_ENDPOINT = BASE_URL + `/getcryptobalance`
  }
  let response = await api.get(API_ENDPOINT)
  return response
}

export const getDepositAddress = async(payload:{Currency:String}) =>{
  

  const API_ENDPOINT = BASE_URL + `/getdepositaddress`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data;
    }
  );
}

export const editDepositAddressName = async(payload:{Address:String, Name:String, Currency:String}) =>{
  

  const API_ENDPOINT = BASE_URL + `/editdepositaddressname`;
  return api.post(API_ENDPOINT, payload).then(
    (response) => {
      // console.log(response)
      return response;
    },
    (err) => {
      console.log("err", err);
      return err?.response?.data?.Message;
    }
  );
}

export async function getCryptoList() {
  
  

  const API_ENDPOINT = BASE_URL + `/getcryptocurrencylist`;
  let response =  await api.get(API_ENDPOINT)
  return response

}

export const getSpotTradeFees = async(baseCoin:string,quoteCoin:string) =>{

  const API_ENDPOINT = BASE_URL + `/getspottradefeeandtds?baseCurrency=${baseCoin}&quoteCurrency=${quoteCoin}`;
  let response =  await api.get(API_ENDPOINT)
  return response  
}

export const placeBuyOrder = async(payload:any) =>{

  const API_ENDPOINT = BASE_URL + `/spottradebuy`;
  let response =  await api.post(API_ENDPOINT,payload )
  return response
}

export const placeSellOrder = async(payload:any) =>{

  const API_ENDPOINT = BASE_URL + `/spottradesell`;
  let response =  await api.post(API_ENDPOINT,payload)
  return response
}

export const getSpotTradeHistoryData = async(params:any) =>{

  const API_ENDPOINT = BASE_URL + `/spottradehistory?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&pairReferenceId=${params.pairReferenceId}`;
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getTradingPairsData = async() =>{

  const API_ENDPOINT = BASE_URL + `/gettradingpairs`;
  let response =  await api.get(API_ENDPOINT)
  return response 
}

export const getFiatHistoryData = async(params:any) =>{

  const API_ENDPOINT = BASE_URL + `/getfiathistory?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`;
  let response =  await api.get(API_ENDPOINT)
  return response
} 

export const getKycUrl = async() =>{

  const API_ENDPOINT = BASE_URL + `/createsignzydigilockerkycurl`;
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getKycStatus = async(requestId:string) =>{
  let API_ENDPOINT
  if(requestId === `0`){
    API_ENDPOINT = BASE_URL + `/getkycinfo`
  }
  else{
    API_ENDPOINT = BASE_URL + `/getkycinfo?signzyRequestId=${requestId}`;
  }
   
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getUpdatedKycStatus = async() =>{
  let API_ENDPOINT = BASE_URL + `/updatekycrequeststatus`
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getUserProfile = async() =>{
  const API_ENDPOINT = BASE_URL + `/getuserprofiledetails`;
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const updateProfile = async (payload:any) =>{
  const API_ENDPOINT = BASE_URL + `/updateuserprofiledetails`;
  let response =  await api.post(API_ENDPOINT,payload)
  return response
}

export const addUserDocs = async(payload:any) =>{
  const API_ENDPOINT = BASE_URL + `/adduserdocument`
  let response =  await api.post(API_ENDPOINT,payload)
  return response
}

export const submitTaxDoc = async(payload:{DocumentReferenceId:string}) =>{
  const API_ENDPOINT = BASE_URL + `/addtaxdeclarationdocument`
  let response =  await api.post(API_ENDPOINT,payload)
  return response    
}

export const verifyPanCard = async(payload:{PanNo:string})=>{
  const API_ENDPOINT = BASE_URL + `/verifiedpancard`
  let response =  await api.post(API_ENDPOINT,payload)
  return response
}

export const getHistoryFilters = async() =>{
  const API_ENDPOINT = BASE_URL + `/gettransactionhistoryfilterdata`
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getHistoryData = async(filterParams:any) =>{
  const API_ENDPOINT = BASE_URL + `/gettransactionhistory?currencyType=${filterParams.currencyType}&entityType=${filterParams.entityType}&startDate=${filterParams.startDate}&endDate=${filterParams.endDate}&pageIndex=${filterParams.pageIndex}&pageSize=${filterParams.pageSize}`
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const getTransactionDetails = async(params:{transactionReference:string,entityType:string}) =>{
  const API_ENDPOINT = BASE_URL + `/gettransactiondetails?transactionReference=${params.transactionReference}&entityType=${params.entityType}`
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const exportHistory = async(payload:{CurrencyType:string,EntityType:string,StartDate:string,EndDate:string}) =>{
  const API_ENDPOINT = BASE_URL + `/transactionhistorydataexporttoexcel`
  let response =  await api.post(API_ENDPOINT,payload)
  return response   
}

export const storeFcmToken = async(payload:{FCMToken:string,DeviceId:string,Platform:string}) =>{
  const API_ENDPOINT = BASE_URL + `/savefcmtoken`
  let response =  await api.post(API_ENDPOINT,payload)
  return response   
}

export const getBankDetails = async(refenceId:string)=>{
  const API_ENDPOINT = BASE_URL + `/getbank?bankReferenceId=${refenceId}`
  let response =  await api.get(API_ENDPOINT)
  return response  
}

export const cryptoWithdrawlRequest = async(reqCurrencyType:string) =>{
  const API_ENDPOINT = BASE_URL + `/cryptowithdrawalonboarding?currencyType=${reqCurrencyType}`
  let response =  await api.get(API_ENDPOINT)
  return response
}

export const logout = async() =>{
  const API_ENDPOINT = BASE_URL + `/logout`
  let response =  await api.post(API_ENDPOINT)
  return response
}

