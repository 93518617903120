import { Navigate } from "react-router-dom";

export const AuthRoute = ({ children }: any) => {
  let authToken =  localStorage.getItem("token")
    
    if (authToken) {
        return <Navigate to="/home" />;
    } else {
        return children;
      }
  
};
