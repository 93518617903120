import axios from "axios";

const formatDataApi = axios.create({
    baseURL: '${process.env.REACT_APP_SERVER_URL}',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
    },
  })

  formatDataApi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  formatDataApi.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
  
          try {
          
            const rs = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/refreshtoken`, {
              refreshToken: localStorage.getItem("refreshToken"),
        });
  
            localStorage.setItem('userInfo',JSON.stringify(rs.data))
            localStorage.setItem("userInfo", JSON.stringify(rs.data))
            localStorage.setItem("token", rs.data.Token)
            localStorage.setItem("refreshToken", rs.data.RefreshToken)
  
            return formatDataApi(originalConfig);
          } catch (_error) {
            localStorage.removeItem('userInfo')
            localStorage.removeItem("userInfo" )
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken")
            window.location.href= `${process.env.REACT_APP_URL}`
            return Promise.reject(_error);
          }
        }
      }
  
      return Promise.reject(err);
    }
  );


export default formatDataApi;