// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging ,onMessage} from "firebase/messaging";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const requiredApiKey= process.env.REACT_APP_apiKey
const requiredAuthDomain = process.env.REACT_APP_authDomain
const requiredDbUrl = process.env.REACT_APP_databaseURL
const requiredProjectId = process.env.REACT_APP_projectId
const requiredStorageBucket = process.env.REACT_APP_storageBucket
const requiredMessagingSenderId = process.env.REACT_APP_messagingSenderId
const requiredAppId = process.env.REACT_APP_appId
const requiredMeasurementId = process.env.REACT_APP_measurementId


const firebaseConfig = {
  apiKey: requiredApiKey,
  authDomain:  requiredAuthDomain ,
  databaseURL: requiredDbUrl,
  projectId: requiredProjectId,
  storageBucket: requiredStorageBucket,
  messagingSenderId: requiredMessagingSenderId,
  appId: requiredAppId,
  measurementId: requiredMeasurementId
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const messaging = getMessaging(app)
export const db = getDatabase(app);


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload:any) => {
      resolve(payload);
    });
});