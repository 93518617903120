import React, { useEffect, useState } from "react";
import "../App.css";
import "../components/Signup/Signup.css";
import LOGO from "../assets/images/LOGO.svg";
import india from "../assets/images/india.svg";
import star8 from "../assets/images/Star8.svg";
import hand from "../assets/images/hand.svg";
import star9 from "../assets/images/Star9.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import { FiChevronDown } from "react-icons/fi";
import { countryCodes } from "../config/conutry_code";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Axios from "../utils/API";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';
import Loading from '../components/Loading';
import parsePhoneNumber from 'libphonenumber-js'
import Toast from 'react-bootstrap/Toast';
import { BsCheckCircle } from "react-icons/bs";
import { BiCheckCircle } from "react-icons/bi";
import {AiOutlineCloseCircle} from "react-icons/ai";

export default function Signup() {

  const [show, setShow] = useState(false);
  const [loading,setLoading] = useState(false)
  const [captchaToken,setCaptchaToken] = useState<string | null>(null)
  const [verified, setVerified] = useState(false);
  const [captchaSiteKey,SetSiteKey] = useState(process.env.REACT_APP_SITE_KEY)
  const [countrycode, setCountrycode] = React.useState("+91");
  const [numberError,setNumberError] = useState({status:false, message:""})
  const [captchError,setCaptchError] = useState({status:false, message:""})
  const [apiCallInProgress,setApiCallInProgress] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();

  const [countryList, setCountryList] = useState<any[]>([])
  useEffect(() => {
    // console.log("demo",captchaSiteKey)
    setLoading(true)
    getCountry();
  }, []);

  let getCountry = async () => {
    try{
    let registerUser:any = await Axios.listCountryCode();
    setCountryList(registerUser.data.List)
    setLoading(false)
    }
    catch(error:any){
      console.log(error?.response?.data?.Message)
      setLoading(false)
    }
  };


  const handleChange = (event: SelectChangeEvent) => {
    setCountrycode(event.target.value as string);
  };

  const [number, setNumber] = useState("");

  const checkInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // const onlyDigits = e.target.value.replace(/\D/g, "");
    setNumber(e.target.value);
  };


  // reCAPTCHA Function
  function onChange(value: any) {
    setCaptchaToken(value)
    setVerified(true);
  }

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const handleNavigate = async () => {
    
    if (number === "") {
      return setNumberError({status:true,message:"Mobile number is required."})
    }
    // console.log("log",countrycode)
    let phoneNumberObj = parsePhoneNumber(countrycode+number)
    // console.log("log2",phoneNumberObj?.isValid())
    if(!phoneNumberObj?.isValid()){
      return setNumberError({status:true,message:"Invalid Mobile Number."})
    }
    
    if(!captchaToken){
      return setCaptchError({status:true,message:"Captcha should be validated."})
    }
    setApiCallInProgress(true)
    let payload = {
      PhoneNumber: number,
      CountryCode: countrycode.toString(),
      ReCaptchaToken:captchaToken
    };
    const loginUser = await Axios.login(payload);
    if (loginUser?.ReturnCode === 200) {
      localStorage.setItem("countrycode", countrycode);
      localStorage.setItem("number", number)
      localStorage.setItem("UserReferenceId", loginUser?.UserReferenceId);
      localStorage.setItem(
        "PhoneNumberOTPReferenceId",
        loginUser?.PhoneNumberOTPReferenceId
      );
      localStorage.setItem("lastVisitedPage", location.pathname.toString())
      setApiCallInProgress(false)
      navigate("/enterverificationcode");
    } else {
      setApiCallInProgress(false)
      toast.error(loginUser?.toString(), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    }  
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Log In to BuyBitz</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {
        !loading?
      <section className="vh-100 section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
           <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card border-gr">
              <div className="card-body position-relative">
                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4 mb-2">
                    Hi Welcome <img src={hand} alt="" />{" "}
                  </h2>
                  <img src={star8} alt="" className="star8" />
                </div>
                <p className="font-bold4 fs-6 authentication-card-sub-heading color1 inter-fstyle">
                  Log In to BuyBitz
                </p>
                <form className="authentication-form mt-5">
                  <Form.Label className={numberError.status?"authentication-card-label alertColor font-bold2" : "authentication-card-label color1 font-bold2"}>
                    Country
                  </Form.Label>
                  <InputGroup className="d-flex align-items-center position-relative">
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="m-0 country-code font-bold2"
                    >
                      <Select
                        value={countrycode}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                          {countryList.map((item, index) => (
                          <MenuItem value={item?.Code} key={index}>
                            <img src={item?.URL} alt="" className="flag-img" style={{height: 15}} />
                            &nbsp;{item?.Code}
                            <p className="county-code-name mb-0 ps-2">{item?.Name}</p>
                          </MenuItem>
                        ))}
                      </Select>
                      <FiChevronDown />
                    </FormControl>
                    <Form.Control
                      placeholder="Mobile Number"
                      aria-label="Mobile Number"
                      aria-describedby="basic-addon1"
                      className={numberError.status? 'red-border-element authentication-card-input-code':"authentication-card-input-code"}
                      type="number"
                      maxLength={15}
                      value={number}
                      onChange={(e) => checkInput(e)}
                    />
                
                  </InputGroup>
                  {
                    numberError.status?
                    <p className="red cc-error-msg mb-0 mt-1 text-center">{numberError.message}</p>
                    :
                    null
                  }
              
                    <form className="recaptcha d-flex justify-content-center mt-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={captchaSiteKey?captchaSiteKey:''}
                        onChange={onChange}
                        theme="dark"
                        onExpired={() => {
                          setVerified(false);
                          if (recaptchaRef.current) {
                            recaptchaRef.current.reset();
                          }
                        }}
                      />
                    <p className={captchError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{captchError.message}</p>
                    </form>
                    
                      <div className="text-center">
                        {/* <Link to="/enterverificationcode"> */}
                            <Button
                            variant="primary"
                            className="authentication-button"
                            disabled={(!verified || apiCallInProgress)}
                            onClick={handleNavigate}
                           >
                             Log In
                           </Button>
                         
                          
                        {/* </Link> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-center bottom-margin mb-4">
                     
                        <p className="mb-0 font-bold3 authentication-card-sub-heading2 inter-fstyle color1 me-2">Don’t have an account?</p>
                        <Link to="/signup" className="font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover">Sign Up</Link>
                      </div>
                  </form>
                  
              </div>
            </div>
          </div>
        </div>
      </section>
      :
      <Loading type='pageLoader'/>
      }
    </div>
  );
}
