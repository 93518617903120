import axios from "axios";



const api = axios.create({
    baseURL: '${process.env.REACT_APP_SERVER_URL}',
    headers: {
      "Content-Type": "application/json",
    },
  })

  let accessToken = localStorage.getItem("token")

  api.interceptors.request.use(
    (config) => {
      accessToken = localStorage.getItem("token");
      if (accessToken) {
        config.headers["Authorization"] = 'Bearer ' + accessToken;  
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  let refreshToken:any = null

  api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
  
          try {
            refreshToken = refreshToken? refreshToken: axios.post(`${process.env.REACT_APP_SERVER_URL}/api/refreshtoken`, {refreshToken: localStorage.getItem("refreshToken"),})
            let res = await refreshToken
            refreshToken = null
            
            if(res.data.Token){
              accessToken = res.data.Token
              localStorage.setItem('userInfo',JSON.stringify(res.data))
              localStorage.setItem("token", res.data.Token)
               localStorage.setItem("refreshToken", res.data.RefreshToken)
            }              
            return api(originalConfig);
          } catch (_error) {
            // console.log("error",_error,localStorage.getItem('refreshToken'))
            localStorage.removeItem('userInfo')
            localStorage.removeItem("userInfo" )
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken")
            accessToken =null
            window.location.href= `${process.env.REACT_APP_URL}`
            return Promise.reject(_error);
          }
        }
      }
  
      return Promise.reject(err);
    }
  );


export default api;