export const ToTrunc = (value:any,n:any)=>{  
    return Math.floor(value*Math.pow(10,n))/(Math.pow(10,n));
}


export const formatDate1 = (requiredDate:string) =>{
  return  new Date(requiredDate).toLocaleDateString('en-US',{year: '2-digit', month: 'short', day: '2-digit' })
}

export const formatDate2 = (requiredDate:string) =>{
  return  `${new Date(requiredDate).getHours()}:${new Date(requiredDate).getMinutes()}:${new Date(requiredDate).getSeconds()<10? `0`+new Date(requiredDate).getSeconds():new Date(requiredDate).getSeconds()}`
}

export const formatDate3 = (requiredDate:string) =>{
  const date = new Date(requiredDate);
  const yyyy = date.getFullYear()
  let mm = `${date.getMonth() + 1}`
  let dd = `${date.getDate()}`
  
  if (+dd < 10) dd = '0' + dd
  if (+mm < 10) mm = '0' + mm
  
  return dd + '/' + mm + '/' + yyyy
}

export const getDateDifference = (date:string) =>{
    let date1 = new Date()
    let date2 = new Date(date)

    let diffInMs = date1.getTime() - date2.getTime()
    let diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));
     return  diffInDays
  }

export const formatOrderId = (txHash:string) =>{
    
    return txHash.length >10 ?`${txHash.slice(0,7)}...`:txHash
}  

export const tusdtSymbolHandler = (coinSymbol:string) =>{
      return  coinSymbol === `TUSDT`?`USDT`:coinSymbol      
}

export const findCurrency=()=>{
    let code = localStorage.getItem('countrycode')
    let requiredCurrency = 'INR'    

    if(code === "+91"){
      requiredCurrency= 'INR'
    }
    else if(code === "+880"){
      requiredCurrency= 'BDT'
    }
    else{
      requiredCurrency= 'EUR'
    }
    
    return requiredCurrency
} 

