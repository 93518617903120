import React, { useEffect, useState } from "react";
import "../App.css";
import "../components/Signup/Signup.css";
import LOGO from "../assets/images/LOGO.svg";
import india from "../assets/images/india.svg";
import star8 from "../assets/images/Star8.svg";
import star9 from "../assets/images/Star9.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import { FiChevronDown } from "react-icons/fi";
import { countryCodes } from "../config/conutry_code";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Axios from "../utils/API";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';
import { parsePhoneNumber } from "libphonenumber-js";

export default function Update() {
  const [countrycode, setCountrycode] = React.useState("+91");
  const [captchaToken,setCaptchaToken] = useState<string | null>(null)
  const [captchaSiteKey,SetSiteKey] = useState(process.env.REACT_APP_SITE_KEY)
  const [loading,setLoading] = useState(false)
  const [apiCallInProgress,setApiCallInProgress] = useState(false)


  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: SelectChangeEvent) => {
    setCountrycode(event.target.value as string);
  };

  const [number, setNumber] = useState("");


  useEffect(() => {
    // localStorage.setItem("dynamicToken", location.search.split("=")[1])
  }, [])

  const [countryList, setCountryList] = useState<any[]>([])

  useEffect(() => {
    
    getCountry();
  }, []);

  let getCountry = async () => {
    try{
    let registerUser:any = await Axios.listCountryCode();
    setCountryList(registerUser.data.List)
    setLoading(false)
    }
    catch(error:any){
      console.log(error?.response?.data?.Message)
      setLoading(false)
    }
  };

  const checkInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setNumber(onlyDigits);
  };

  const [verified, setVerified] = useState(false);

  // reCAPTCHA Function
  function onChange(value: any) {
    setCaptchaToken(value)
    setVerified(true);
  }

  const handleNavigate = async () => {
    let phoneNumberObj = parsePhoneNumber(countrycode+number)
    if (number === "") {
      // alert("Please enter the mobile number");
      toast.error("Please enter the mobile number", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    }
    else if(!phoneNumberObj?.isValid()){
      toast.error("Invalid Mobile Number.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    } 
    else {
      setApiCallInProgress(true)
      localStorage.setItem("dynamicToken", location.search.split("=")[1])
      localStorage.setItem('countrycode',countrycode.toString())
      let payload = {
        PhoneNumber: number,
        CountryCode: countrycode.toString(),
        token: location.search.split("=")[1],
        ReCaptchaToken:captchaToken
      };
      const updateUser = await Axios.login(payload);
      if (updateUser?.ReturnCode === 200) {
        localStorage.setItem("number", number)
        localStorage.setItem("UserReferenceId", updateUser?.UserReferenceId);
        localStorage.setItem(
          "PhoneNumberOTPReferenceId",
          updateUser?.PhoneNumberOTPReferenceId
        );
        localStorage.setItem("lastVisitedPage", location.pathname.toString())
        setApiCallInProgress(false)
        navigate("/enterverificationcode");
      } else {
        // alert("Some error occured");
        setApiCallInProgress(false)
        toast.error(updateUser?.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
    }
  };

  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        <title> Update PIN</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card border-gr">
              <div className="card-body position-relative">
                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4 mb-2">
                    Update PIN {" "}
                  </h2>
                  <img src={star8} alt="" className="star8" />
                </div>
                <p className="font-bold4 fs-6 authentication-card-sub-heading color1 inter-fstyle update-text">
                  BuyBitz will send a one time SMS to verify your phone number
                </p>
                <form className="authentication-form mt-5">
                  <Form.Label className="authentication-card-label color1 font-bold2">
                    Country
                  </Form.Label>
                  <InputGroup className="mb-4 mb-md-5 d-flex align-items-center position-relative">
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="m-0 country-code font-bold2"
                    >
                      <Select
                        value={countrycode}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {/* {countryCodes.map((item, index) => (
                          <MenuItem value={item.code} key={index}>
                            <img src={india} alt="" />
                            &nbsp;{item.code}
                          </MenuItem>
                        ))} */}
                          {countryList.map((item, index) => (
                          <MenuItem value={item.Code} key={index}>
                            <img src={item.URL} alt="" className="flag-img" style={{height: 15}} />
                            &nbsp;{item.Code}
                            <p className="county-code-name mb-0 ps-2">{item.Name}</p>
                          </MenuItem>
                        ))}
                        {/* <MenuItem value={10}>
                            <img src={india} alt="" />
                            &nbsp;+91
                          </MenuItem>
                          <MenuItem value={20}>
                            <img src={india} alt="" />
                            &nbsp;+914
                          </MenuItem>
                          <MenuItem value={30}>
                            <img src={india} alt="" />
                            &nbsp;+9551
                          </MenuItem> */}
                      </Select>
                      <FiChevronDown />
                    </FormControl>
                    <Form.Control
                      placeholder="Mobile Number"
                      aria-label="Mobile Number"
                      aria-describedby="basic-addon1"
                      className="authentication-card-input-code"
                      type="number"
                      maxLength={15}
                      value={number}
                      onChange={(e) => checkInput(e)}
                    />
                  </InputGroup>

                  {/* <div className="custom-form-check mb-4">
                      <sup className="star me-2">
                        <img src={star} alt="" />
                      </sup>
                      <div className="form-check d-flex">
                        <input
                          className="form-check-input custom-check"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label custom-check-label color1"
                          htmlFor="flexCheckChecked"
                        >
                          I have read and understood the Terms & Conditions
                        </label>
                      </div>
                    </div> */}
                  {/* <div className="authentication-bottom-sec"> */}
                    {/*Adding reCAPTCHA*/}
                    <form className="recaptcha d-flex justify-content-center mb-4">
                      <ReCAPTCHA
                        sitekey={captchaSiteKey?captchaSiteKey:''} //This is a test key
                        onChange={onChange}
                        theme="dark"
                      />
                    </form>

                    <div className="text-center">
                      {/* <Link to="/enterverificationcode"> */}
                      <Button
                        variant="primary"
                        className="authentication-button"
                        disabled={!verified || apiCallInProgress}
                        onClick={handleNavigate}
                      >
                        Log In
                      </Button>
                      {/* </Link> */}
                    </div>
                    <div className="d-flex align-items-center justify-content-center bottom-margin mb-4">
                      <p className="mb-0 font-bold3 authentication-card-sub-heading2 inter-fstyle color1 me-2">
                        Don’t have an account?
                      </p>
                      <Link
                        to="/signup"
                        className="font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover"
                      >
                        Sign Up
                      </Link>
                    </div>
                  {/* </div> */}
                </form>
                {/* <div className="authentication-bottom-sec">
                  <div className="text-center">
                    <Button
                      variant="primary"
                      className="authentication-button"
                      disabled={!verified}
                      onClick={handleNavigate}
                    >
                      Log In
                    </Button>
                  </div>
                  <div className="d-flex align-items-center justify-content-center bottom-margin">
                    <p className="mb-0 font-bold3 authentication-card-sub-heading2 inter-fstyle color1 me-2">
                      Don’t have an account?
                    </p>
                    <Link
                      to="/signup"
                      className="font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
