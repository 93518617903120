import React, { FC,useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../App.css";
import "../../components/SignIn/SignIn.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import * as Axios from "../../utils/API";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { setUser } from '../../redux/index'

interface PinProps{
  email:string,
  number:string,
  countrycode:string,
  setUser:any  
}

const Pin:FC<PinProps> =(props): JSX.Element=> {

  const navigate = useNavigate();
  const location = useLocation();

  const [pin, setPin] = useState("");
  const [attempt, setAttempt] = useState(0);
  const [webStatus,setWebStatus] = useState(2)
  const [mobile_number,setMobileNumber] = useState('')
  const [apiCallInProgress,setApiCallInProgress] = useState(false)

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('number')

    if(requiredNumber){
      setMobileNumber(requiredNumber)
    }
    else{
      navigate('/')
    }
  }

  const calculateAttempt = async () => {
    let payload = {
      UserReferenceId: localStorage.getItem("UserReferenceId"),
    };
    const remainingAttempt = await Axios.wrongPinAttempt(payload);
    if (remainingAttempt?.ReturnCode === 200) {
      setAttempt(remainingAttempt?.RemainAttemptCount);
    } else {
      localStorage.setItem("isUserRestricted", "true");
      localStorage.setItem("lastVisitedPage", location.pathname);
      // navigate("/accountrestricted");
    }
  };

  const handleNavigate = async () => {
    if (pin === "") {
      // alert("Please enter the mobile number");
      return
    }
    setApiCallInProgress(true)
    let payload;
    if (localStorage.getItem("lastVisitedPage") === "/") {
      payload = {
        UserReferenceId: localStorage.getItem("UserReferenceId"),
        OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
        PIN: pin,
        IsWeb:webStatus
      };
    } else {
      payload = {
        UserReferenceId: localStorage.getItem("UserReferenceId"),
        Token: localStorage.getItem("Token"),
        PIN: pin,
        IsWeb:webStatus
      };
    }
    const validateUser = await Axios.validatePIN(payload);
    const validateAttempts = await calculateAttempt();
    if (validateUser?.ReturnCode === 200) {
      props.setUser(
        {email:validateUser.Email,
         number:validateUser.PhoneNumber,
         countrycode:validateUser.CountryCode,
         firstName:validateUser.FirstName,
         lastName:validateUser.LastName,
         kycStatus:validateUser.KYCStatus,
         kycRejectionReasons:validateUser.KYCRejectionReasons,
         isDocumentsRequired:validateUser.validateUser,
         publicUserUid:validateUser.PublicUserUid
        }
        )
      localStorage.setItem("lastVisitedPage", location.pathname);
      localStorage.setItem("token", validateUser.Token)
      localStorage.setItem("isDocumentsRequired", validateUser.IsDocumentsRequired)
      localStorage.setItem("refreshToken", validateUser.RefreshToken)
      localStorage.setItem('amountVisibilityStatus',`1`)
      // Axios.updateToken(true)
      if(!validateUser.IsDocumentsRequired){
        navigate("/home");
      }
      else{
        navigate('/documentRestrictions')
      }
      setApiCallInProgress(false)
    } else {
      setApiCallInProgress(false)
      toast.error(validateUser.Message.toString(), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      })
      if(validateUser.ReturnCode === 403 && validateUser.Message.toString() === "User is blocked"){
        navigate("/accountrestricted");
      }
      
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enter Your 4 Digit PIN</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                {/* <Link to="/enterverificationcode" className="back-icon-cover">
                  <MdArrowBackIosNew className="back-icon" />
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 evc-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Enter Your 4 Digit PIN
                  </h2>
                  <img src={star8} alt="" className="star8 evc-star8" />
                </div>
                <p className="font-bold2 authentication-card-sub-heading color1 poppins-fstyle">
                  Mobile Number Verified
                </p>
                <p className="font-bold4 authentication-card-sub-heading color1 inter-fstyle">
                  {localStorage.getItem('countrycode')} {mobile_number}
                </p>
                <form className="authentication-enter-verify-form  authentication-form mt-5">
                  <div className="mb-4">
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) => {
                        setPin(value);
                      }}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      onComplete={(value) => setPin(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    {attempt >= 1 && (
                      <p className="text-capitalize color1 poppins-fstyle text-center mt-3 error-msg">
                        Incorrect PIN. {attempt} Attempt remaining
                      </p>
                    )}
                  </div>

                  <div className="text-center mt-3 mb-3 mt-sm-5">
                    {/* <Link to="/forgotpin"> */}
                    <Link
                      to="/resetpininfo"
                      className="d-flex justify-content-center  font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover mb-5"
                    >
                      Forgot PIN?
                    </Link>
                    {/* </Link> */}
                    {/* <Link to="/"> */}
                    <Button
                      variant="primary"
                      className="authentication-button "
                      disabled={(pin === ""|| apiCallInProgress) ? true : false}
                      onClick={handleNavigate}
                    >
                      Log In
                    </Button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state:any) => {
  return {
    email:state.user.email,
    number:state.user.number,
    countrycode:state.user.countrycode
  };
};

const mapStateToDispatch = (dispatch:any) => {
  return {
    setUser: (userCreds:{email:string,number:number,countrycode:string})=>dispatch(setUser(userCreds))
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Pin);