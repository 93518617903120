import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import "../App.css";
import "../components/Signup/Signup.css";
import LOGO from "../assets/images/LOGO.svg";
import star8 from "../assets/images/Star8.svg";
import star9 from "../assets/images/Star9.svg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import { FiChevronDown } from "react-icons/fi";
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Axios from "../utils/API";
import { color } from "@mui/system";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { setUser } from '../redux/index'
import { parsePhoneNumber } from "libphonenumber-js";

function Signup(props:any) {
  const navigate = useNavigate();
  const location = useLocation();

  const [countrycode, setCountrycode] = useState("+91");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [checked, setChecked] = useState(false);
  const [captchaSiteKey,SetSiteKey] = useState(process.env.REACT_APP_SITE_KEY)
  const [captchaToken,setCaptchaToken] = useState<string | null>(null)
  const [captchError,setCaptchError] = useState({status:false, message:""})

  const [mobError,setMobError] = useState({status:false,message:""})
  const [emailError,setEmailError] = useState({status:false,message:""})
  const [firstNameError,setFirstNameError] = useState({status:false,message:""})
  const [lastNameError,setlastNameError] = useState({status:false,message:""})
  const [pin1Error,setPin1Error] = useState({status:false,message:""})
  const [pin2Error,setPin2Error] = useState({status:false,message:""})
  const [pinError,setPinError] = useState({status:false,message:""})
  const [apiCallInProgress,setApiCallInProgress] = useState(false)


  const [countryList, setCountryList] = useState<any[]>([]);

  const [showPasswordsPin, setShowPasswordsPin] = useState(false);
  const [showPasswordsCPin, setShowPasswordsCPin] = useState(false);
  const [loading,setLoading] = useState(false)


  useEffect(() => {
    
    getCountry();
  }, []);

  useEffect(()=>{

  },[mobError.status,
    emailError.status,
    firstNameError.status,
    lastNameError.status,
    pin1Error.status,
    pin2Error.status,
    pinError.status,])

  let getCountry = async () => {
    try{
    let registerUser:any = await Axios.listCountryCode();
    setCountryList(registerUser.data.List)
    setLoading(false)
    }
    catch(error:any){
      console.log(error?.response?.data?.Message)
      setLoading(false)
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCountrycode(event.target.value as string);
  };

  const checkInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
      const onlyDigits = e.target.value.replace(/\D/g, "");
      setNumber(onlyDigits);
    
  };

  const checkInputPin = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    if (type === "pin") {
      setPin(onlyDigits);
    } else {
      setConfirmPin(onlyDigits);
    }
  };

  const [verified, setVerified] = useState(false);

  // reCAPTCHA Function
  function onChange(value: any) {
    setCaptchaToken(value)
    setVerified(true);
  }

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const handleNavigate = async () => {
    let havingError= false
    let havingMobError = false
    let havingFnError = false
    let havingLnError = false
    let havingPinError =false
    let havingEmailError = false    
    let havingPin1Error = false
    let havingPin2Error = false
      if(!number.length){
        havingMobError= true
         setMobError({status:true,message:'Mobile number is required.'})
      } 
     
    // console.log("log",countrycode)
    let phoneNumberObj = parsePhoneNumber(countrycode+number)
    // console.log("log2",phoneNumberObj?.isValid())
      if (
        !phoneNumberObj?.isValid()
      ) {
        havingMobError= true
         setMobError({status:true,message:'Invalid Mobile Number.'})
      }
      else{
        havingMobError=false
        setMobError({status:false,message:''})
      }

      // Checking Valid First Name
      if ( 
        !firstName.length 
      ) {
        havingFnError= true
        setFirstNameError({status:true,message:"First Name is required."})
      }
     
      if ( 
        !/^[A-Za-z\s]*$/.test(firstName) && !havingFnError
      ) {
        havingFnError= true
        setFirstNameError({status:true,message:"First Name should be valid."})
        // toast.error("Please enter a valid first Name");
      }
      else if(/^[A-Za-z\s]*$/.test(firstName)){
        havingFnError=false
        setFirstNameError({status:false,message:''})
      }


      // Checking Valid Last Name
      if ( 
        !lastName.length
      ) {
        havingLnError= true
         setlastNameError({status:true,message:"Last Name is required."})
      }

      if ( 
        !/^[a-zA-Z]+$/.test(lastName) && !havingLnError
      ) {
        havingLnError= true
         setlastNameError({status:true,message:"Last Name should be valid."})
      }
      else if(/^[a-zA-Z]+$/.test(lastName)){
        havingLnError=false
        setlastNameError({status:false,message:''})
      }

      // Validating Email address
      if ( 
        !email.length
      ) {
        havingEmailError= true
        setEmailError({status:true,message:"Email is required."})
      }  

      if (
        !/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(email) && !havingEmailError
      ) {
        havingEmailError= true
        setEmailError({status:true,message:'Valid email is required.'})
      }
      else if(/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(email)){
        havingEmailError= false
        setEmailError({status:false,message:""})
      }

      if ( 
        !pin.length
      ) {
        havingPin1Error= true
        setPin1Error({status:true,message:"PIN is required."})
      } 
      
      if(pin.length < 4  && !havingPin1Error){
        havingPin1Error= true
        setPin1Error({status:true,message:"PIN should be valid."})
      }
      else if(pin.length === 4){
        havingPin1Error= false
        setPin1Error({status:false,message:""})
      }

      if ( 
        !confirmPin.length 
      ) {
        havingPin2Error= true
        setPin2Error({status:true,message:"Confirm PIN is required."})
      } 

      // console.log("log",confirmPin.length)
      if(confirmPin.length < 4  && !havingPin2Error){
        havingPin2Error= true
        setPin2Error({status:true,message:"Confirm PIN should be valid."})
      }
      else if(confirmPin.length === 4){
        havingPin2Error= false
        setPin2Error({status:false,message:""})
      }
      // console.log("log2",pin,confirmPin)

      if (pin !== confirmPin && !havingPinError) {
        havingPinError= true
        setPinError({status:true,message:"PIN doesn't match."})
      }
      else if(pin === confirmPin && pin.length ===4 && confirmPin.length === 4){
        havingPinError= false
        setPinError({status:false,message:""})
      } 

      if(!captchaToken){
        havingError= true
        return setCaptchError({status:true,message:"Captcha should be validated."})
      }
      else if(captchaToken){
        havingError= false
        setCaptchError({status:false,message:""})
      }

      if(!havingError &&
        !havingMobError &&
        !havingFnError &&
        !havingLnError &&
        !havingPinError &&
        !havingEmailError &&
        !havingPin1Error &&
        !havingPin2Error){
        setApiCallInProgress(true)
        let payload = {
          PhoneNumber: number,
          CountryCode: countrycode.toString(),
          Email: email.toString(),
          PIN: pin,
          FirstName: firstName.toString().trim(),
          LastName: lastName.toString().trim(),
          ReCaptchaToken:captchaToken
        };
        const registerUser = await Axios.registration(payload);
        if (registerUser?.ReturnCode === 200) {
          props.setUser({email,number,countrycode})
          localStorage.setItem("countrycode", countrycode);
          localStorage.setItem("email", email)
          localStorage.setItem("number", number)
          localStorage.setItem(
            "UserReferenceId",
            registerUser?.UserReferenceId
          );
          localStorage.setItem(
            "EmailOTPReferenceId",
            registerUser?.EmailOTPReferenceId
          );
          localStorage.setItem(
            "PhoneNumberOTPReferenceId",
            registerUser?.PhoneNumberOTPReferenceId
          );
          localStorage.setItem("lastVisitedPage", location.pathname.toString());
          setApiCallInProgress(false)
          navigate("/verifyouraccount");
        } else {
          toast.error(registerUser?.toString(), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            className: "custom-toast"
          });
          setApiCallInProgress(false)
        }
      }
    
  };


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Sign up</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section">

        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Sign up
                  </h2>
                  <img src={star8} alt="" className="star8" />
                </div>
                <form className="authentication-form">
                  <Form.Label className={mobError.status?"authentication-card-label alertColor font-bold2": "authentication-card-label color1 font-bold2"}>
                    Country
                  </Form.Label>
                  <InputGroup className="d-flex align-items-center position-relative">
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="m-0 country-code font-bold2"
                    >
                      <Select
                        value={countrycode}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {/* {countryCodes.map((item, index) => (
                          <MenuItem value={item.code} key={index}>
                            <img src={india} alt="" className="flag-img" />
                            &nbsp;{item.code}
                          </MenuItem>
                        ))} */}
                        {countryList.map((item, index) => (
                          <MenuItem value={item.Code} key={index}>
                            <img
                              src={item.URL}
                              alt=""
                              className="flag-img"
                              style={{ height: 15 }}
                            />
                            &nbsp;{item.Code}
                            <p className="county-code-name mb-0 ps-2">
                              {item.Name}
                            </p>
                          </MenuItem>
                        ))}
                        {/* <MenuItem value={10}><img src={india} alt="" />&nbsp;+91</MenuItem>
                             <MenuItem value={20}><img src={india} alt="" />&nbsp;+914</MenuItem>
                             <MenuItem value={30}><img src={india} alt="" />&nbsp;+9551</MenuItem>   */}
                      </Select>
                      <FiChevronDown />
                    </FormControl>
                    <Form.Control
                      placeholder="Mobile Number"
                      aria-label="Mobile Number"
                      aria-describedby="basic-addon1"
                      type="number"
                      maxLength={15}
                      value={number}
                      onChange={(e) => checkInput(e)}
                      className={mobError.status? 'red-border-element authentication-card-input-code':"authentication-card-input-code"} 
                    />  
                  </InputGroup>
                  <p className={mobError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{mobError.message}</p>

                  <Row className="mb-3 mt-3">
                    <Form.Group
                      className="col position-relative"
                      controlId="formGridpin"
                    >
                      <Form.Label className={firstNameError.status?"authentication-card-label alertColor font-bold2" : "authentication-card-label color1 font-bold2"}>
                        FIRST NAME
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="John"
                        className={firstNameError.status? 'red-border-element authentication-card-input':"authentication-card-input"}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        value={firstName}
                      />
                      <p className={firstNameError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{firstNameError.message}</p>
                    </Form.Group>

                    <Form.Group
                      className="col position-relative"
                      controlId="formGridcpin"
                    >
                      <Form.Label className={lastNameError.status?"authentication-card-label alertColor font-bold2": "authentication-card-label color1 font-bold2"}>
                        LAST NAME
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Doe"
                        className={lastNameError.status? 'red-border-element authentication-card-input':"authentication-card-input"}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        value={lastName}
                      />
                    <p className={lastNameError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{lastNameError.message}</p>

                    </Form.Group>

                  </Row>

                  <Form.Group className="col mb-3" controlId="formGridEmail">
                    <Form.Label className={emailError.status?"authentication-card-label alertColor font-bold2": "authentication-card-label color1 font-bold2"}>
                      Email id
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="example@gmail.com"
                      className={emailError.status? 'red-border-element authentication-card-input':"authentication-card-input"}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      value={email}
                    />
                    <span className={emailError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>{emailError.message}</span>
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group
                      className="col position-relative"
                      controlId="formGridpin"
                    >
                      <Form.Label className={pin1Error.status||pinError.status ?"authentication-card-label alertColor font-bold2": "authentication-card-label color1 font-bold2"}>
                        PIN
                      </Form.Label>
                      <Form.Control
                        type={showPasswordsPin ? "tel" : "password"}
                        maxLength={4}
                        placeholder="4 digit PIN"
                        className={pin1Error.status||pinError.status? 'red-border-element authentication-card-input':"authentication-card-input"} 
                        onChange={(e) => checkInputPin(e, "pin")}
                        value={pin}
                      />
                      
                      {
                       !showPasswordsPin? 
                      <IoEyeOffOutline
                        className="eye-color color1"
                        onClick={() => {
                            setShowPasswordsPin(!showPasswordsPin);
                        }}
                      />
                      :
                      <IoEyeOutline
                      className='eye-color color1'
                      onClick={() => {
                        setShowPasswordsPin(!showPasswordsPin);
                       }}
                      />
                    }
                    <div >
                      <p className={pin1Error.status?"alertColor showVisibility" : "alertColor removeVisibility"}>
                        {pin1Error.message}
                      </p>
                    </div>
                    </Form.Group>

                    <Form.Group
                      className="col position-relative"
                      controlId="formGridcpin"
                    >
                      <Form.Label className={ pin1Error.status||pin2Error.status||pinError.status ?"authentication-card-label alertColor font-bold2": "authentication-card-label color1 font-bold2"}>
                        Confirm PIN
                      </Form.Label>
                      <Form.Control
                        type={showPasswordsCPin ? "tel"  : "password"}
                        maxLength={4}
                        placeholder="4 digit PIN"
                        className={pin1Error.status||pin2Error.status|| pinError.status? 'red-border-element authentication-card-input':"authentication-card-input"}
                        onChange={(e) => checkInputPin(e, "cPin")}
                        value={confirmPin}
                      />
                      {
                       !showPasswordsCPin? 
                      <IoEyeOffOutline
                        className="eye-color color1"
                        onClick={() => {
                          setShowPasswordsCPin(!showPasswordsCPin);
                        }}
                      />
                      :
                      <IoEyeOutline
                      className='eye-color color1'
                      onClick={() => {
                        setShowPasswordsCPin(!showPasswordsCPin);
                       }}
                      />
                    }
                    <div >
                      <p className={pin2Error.status?"alertColor showVisibility" : "alertColor removeVisibility"}>
                        {pin2Error.message}
                      </p>
                    </div>

                    <div >
                      <p className={pinError.status?"alertColor showVisibility" : "alertColor removeVisibility"}>
                        {pinError.message}
                      </p>
                    </div>
                    </Form.Group>
                      
                    
                  </Row>

                  <div
                    className="form-check"
                    style={{ paddingTop: 10, paddingBottom: 20 }}
                  >
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      // value={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                      }}
                      id="flexCheckChecked"
                    />
                    <label
                      className="form-check-label custom-check-label color1"
                      htmlFor="flexCheckChecked"
                    >
                      I have read and understood the <Link to='https://buybitz.com/terms-conditions' target='_blank'>Terms & Conditions</Link>
                    </label>
                  </div>

                  {/*Adding reCAPTCHA*/}
                  <form className="recaptcha d-flex justify-content-center mb-5">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={captchaSiteKey?captchaSiteKey:''} 
                      onChange={onChange}
                      theme="dark"
                      onExpired={() => {
                        setVerified(false);
                        if (recaptchaRef.current) {
                          recaptchaRef.current.reset();
                        }
                      }}
                    />
                  </form>
                </form>

                <div className="text-center">
                  <Button
                    variant="primary"
                    className="authentication-button"
                    disabled={!verified || !checked || apiCallInProgress}
                    onClick={handleNavigate}
                  >
                    Sign Up
                  </Button>
                </div>
                {/* </Link> */}
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <p className="mb-0 font-bold3 authentication-card-sub-heading2 inter-fstyle color1 me-2">
                    Already have an account?
                  </p>

                  <Link
                    to="/"
                    className="font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover"
                  >
                    Log In
                  </Link>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
}

const mapStateToProps = (state:any) => {
  return {
    email:state.user.email,
    number:state.user.number,
    countrycode:state.user.countrycode
  };
};

const mapStateToDispatch = (dispatch:any) => {
  return {
    setUser: (userCreds:{email:string,number:number,countrycode:string})=>dispatch(setUser(userCreds))
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Signup);