import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../components/SignIn/SignIn.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import PinInput from "react-pin-input";
import { toast } from "react-toastify";
import * as Axios from "../../utils/API";
import { Helmet } from "react-helmet-async";

export default function ForgotPin() {
  const navigate = useNavigate();
  const location = useLocation();
  const [apiCallInProgress,setApiCallInProgress] = useState(false)
  const [resentOtp, setResentOtp] = useState<number>(0);
  const [mobile_number,setMobileNumber] = useState('')


  useEffect(() => {}, [resentOtp]);

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('number')

    if(requiredNumber){
      setMobileNumber(requiredNumber)
    }
    else{
      navigate('/')
    }
  }
  // const mobile_number: any = localStorage.getItem("number");
  const [otp, setOtp] = useState("");

  const handleResendOtp = async () => {
    // if (otp === "") {
    //   // toast.error("Please enter the email pin");
    //   toast.error("Please enter the email pin", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //     autoClose: 5000,
    //     className: "custom-toast"
    //   });
    // } else {
      let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        OTPType: 40,
        IsEmailOTP: false,
      };
      const valiadteNewOtp = await Axios.resendOTP(payload);
      if (valiadteNewOtp?.ReturnCode === 200) {
        // toast.success("OTP Sent");s
        setResentOtp((prev) => prev + 1);
      } else {
        toast.error(valiadteNewOtp?.toString(), {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
    // }
  };

  // Random component
  const ResendButton = () => (
    <Button
      variant="primary"
      className="color1 font-bold4 linkhover resend-btn inter-fstyle p-0"
      onClick={handleResendOtp}
    >
      Resend
    </Button>
  );

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state

      return <ResendButton />;
    } else {
      // Render a countdown
      return <span className="counter">{seconds}s</span>;
    }
  };

  const time = React.useMemo(() => {
    return Date.now() + 59000;
  }, [resentOtp]);

  const handleNavigate = async () => {
    if (otp === "") {
      toast.error("Please enter the mobile pin", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
      
    } else {
      setApiCallInProgress(true)
      let payload = {
        ReferenceId: localStorage.getItem("UserReferenceId"),
        OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
        OTP: otp,
      };
      const valiadteUser = await Axios.validateOTP(payload);
      if (valiadteUser?.ReturnCode === 200) {
        let newPayload = {
          UserReferenceId: localStorage.getItem("UserReferenceId"),
          OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
          IsWeb: true,
          URL: `${process.env.REACT_APP_URL}/update?token=`,
        };
        const recoverPassword = await Axios.passwordRecovery(newPayload);
        localStorage.setItem("lastVisitedPage", location.pathname);
        localStorage.setItem("email", recoverPassword?.Email);
       
        if (recoverPassword?.ReturnCode === 200) {
          setApiCallInProgress(false)
          navigate("/resetpin");
        } else {
          setApiCallInProgress(false)
          // toast.error(recoverPassword?.toString());
          toast.error(recoverPassword?.toString(), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            className: "custom-toast"
          });
        }
      } else {
        // alert("Some error occured");
        // toast.error(valiadteUser?.toString());
        setApiCallInProgress(false)
        toast.error(valiadteUser.response.data.Message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Otp Verification</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                {/* <Link to="/Pin" className="back-icon-cover">
                <MdArrowBackIosNew className="back-icon" />
                 
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 evc-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Forgot PIN
                  </h2>
                  <img src={star8} alt="" className="star8 evc-star8" />
                </div>
                <p className="font-bold3 authentication-card-sub-heading2 inter-fstyle color1">
                  Don’t worry! It happens. Please enter the verification code
                  sent to
                </p>
                <p className="font-bold4 authentication-card-sub-heading color1 inter-fstyle">
                  {localStorage.getItem('countrycode')} {mobile_number}
                </p>

                <form className="authentication-enter-verify-form authentication-form">
                  <div className="mb-4">
                    <PinInput
                      length={6}
                      initialValue=""
                      secret
                      onChange={(value, index) => {
                        setOtp(value);
                      }}
                      onComplete={(value) => setOtp(value)}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>

                  <div className="mb-0 inter-fstyle font-bold3 color1 text-end">
                    <Countdown
                      date={time}
                      key={resentOtp}
                      renderer={renderer}
                    />
                  </div>

                  <div className="text-center mb-4">
                    {/* <Link to="/resetpininfo"> */}
                    <Button
                      variant="primary"
                      className="authentication-button evc-btn"
                      disabled={(otp === "" || apiCallInProgress) ? true : false}
                      onClick={handleNavigate}
                    >
                      Continue
                    </Button>
                    {/* </Link> */}
                    <div className="d-flex align-items-center justify-content-center mb-md-0 mt-md-5">
                      <p className="mb-0 font-bold3 authentication-card-sub-heading2 inter-fstyle color1 me-2">
                        Remember Password?
                      </p>
                      <Link
                        to="/"
                        className="font-bold2 authentication-card-sub-heading2 inter-fstyle color1 font-bold4 text-decoration-none linkhover"
                      >
                        Log In
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
