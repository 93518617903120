import React, { useState,useEffect } from "react";
import "../../App.css";
import "../../components/Signup/Signup.css";
import LOGO from "../../assets/images/LOGO.svg";
import star8 from "../../assets/images/Star8.svg";
import star9 from "../../assets/images/Star9.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { toast } from "react-toastify";
import * as Axios from "../../utils/API";
import { Helmet } from "react-helmet";

export default function UpdatePin() {
  // const mobile_number = localStorage.getItem("number");
  const [mobile_number,setMobileNumber] = useState('')

  const navigate = useNavigate();
  const location = useLocation();

  const [pin, setPin] = React.useState("");
  const [apiCallInProgress,setApiCallInProgress] = useState(false)
  const [confirmPin, setConfirmPin] = React.useState("");

  useEffect(()=>{
    getSessionCreds()
  },[])

  const getSessionCreds = async() =>{
    let requiredNumber = localStorage.getItem('number')

    if(requiredNumber){
      setMobileNumber(requiredNumber)
    }
    else{
      navigate('/')
    }
  }

  const handleNavigate = async () => {
    if (pin === "" || confirmPin === "") {
      // alert("Please fill the required fields");
      toast.error("Please fill the required fields", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        className: "custom-toast"
      });
    } else {
      if (pin != confirmPin) {
        // alert("Pin and Confirm Pin are not same");
        toast.error("Pin and Confirm Pin are not same", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          className: "custom-toast"
        });
      } else {
        setApiCallInProgress(true)
        let payload = {
          UserReferenceId: localStorage.getItem("UserReferenceId"),
          OTPReferenceId: localStorage.getItem("PhoneNumberOTPReferenceId"),
          Token: localStorage.getItem("dynamicToken"),
          NewPIN: pin,
          IsWeb:true,
        };
        const passwordRecoveryComfirm = await Axios.passwordRecoveryComfirm(
          payload
        );
        localStorage.setItem("lastVisitedPage", location.pathname);
        if (passwordRecoveryComfirm?.ReturnCode === 200) {
          // localStorage.setItem("userInfo", JSON.stringify(passwordRecoveryComfirm));
          // localStorage.setItem("userInfo", JSON.stringify(passwordRecoveryComfirm))
          // localStorage.setItem("token", JSON.stringify(passwordRecoveryComfirm.Token))
          // localStorage.setItem("token", JSON.stringify(passwordRecoveryComfirm.RefreshToken))
          setApiCallInProgress(false)
          navigate("/pinchanged");
        } else {
          // alert("Some error occured")
          setApiCallInProgress(false)
          toast.error(passwordRecoveryComfirm?.toString(), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            className: "custom-toast"
          });
        }
          // navigate("/pinchanged");
      }
    }
  };

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title> Update PIN</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <section className="vh-100 signup-section section">
        <div className="container">
          <div className="common-gradient-bg"> </div>
          <div className="text-center py-1">
            <img src={LOGO} alt="" className="logo-img" />
          </div>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="card authentication-card  m-auto border-gr">
              <div className="card-body position-relative">
                {/* <Link to="/Signup" className="back-icon-cover">
                  <MdArrowBackIosNew className="back-icon" />
                </Link> */}

                <div className="authentication-card-t-box">
                  <img src={star9} alt="" className="star9 otp-star9" />
                  <h2 className="authentication-card-title font-bold1 mt-2 mt-md-4">
                    Update PIN
                  </h2>
                  <img src={star8} alt="" className="star8 otp-star8" />
                </div>
                <p className="font-bold3 authentication-card-sub-heading color1 inter-fstyle">
                  Mobile Number Verified
                </p>
                <p className="font-bold4 authentication-card-sub-heading color1 inter-fstyle">
                {localStorage.getItem('countrycode')} {mobile_number}
                </p>

                <form className="authentication-verify-form authentication-form">
                  <div className="mb-3 text-center">
                    <Form.Label className="authentication-card-label color1 font-bold2 otp-label mb-3 text-capitalize">
                      Enter New 4 digit PIN
                    </Form.Label>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) => {
                        setPin(value);
                      }}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      onComplete={(value) => setPin(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>
                  <div className="text-center">
                    <Form.Label className="authentication-card-label color1 font-bold2 otp-label mb-3 text-capitalize">
                      Confirm New PIN
                    </Form.Label>
                    <PinInput
                      length={4}
                      initialValue=""
                      secret
                      onChange={(value, index) => {
                        setConfirmPin(value);
                      }}
                      type="numeric"
                      inputMode="number"
                      //  style={{ padding: "10px" }}
                      inputStyle={{ borderColor: "#D8DADC" }}
                      onComplete={(value) => setConfirmPin(value)}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </div>

                  <div className="text-center mb-2 mb-md-4">
                    <div className="mb-0 inter-fstyle font-bold3 color1 mt-2 text-end"></div>
                    {/* <Link to="/verificationsuccesful"> */}
                    <Button
                      variant="primary"
                      className="authentication-button vya-btn"
                      disabled={((pin === "" && confirmPin === "") || apiCallInProgress) ? true : false}
                      onClick={handleNavigate}
                    >
                      Continue
                    </Button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
