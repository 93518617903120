import {SETCOINLIST,RESETCOINLIST} from './coinListActionTypes'

export const setCoinList = (coinList:any) =>{
    return{
        type:SETCOINLIST,
        payload:coinList
    }
}

export const resetCoinList = () =>{
    return{
        type:RESETCOINLIST
    }
}