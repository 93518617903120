import {SETBANKLIST,RESETBANKLIST} from './bankActionTypes'

const initState = {
    bankList:[]
}

export const bankReducer = (state:any=initState,action:any) =>{
  switch(action.type){
    case SETBANKLIST: return{
      bankList: action.payload, 
    }
    case RESETBANKLIST: return{
      ...state,
      bankList: [], 
    }
    default: return state
  }
}